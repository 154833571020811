import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import {
  ActionTypes,
  TypeAction
} from './driver-license.actions';

export interface IState {
  status: number | null;
  isSubmitted: boolean;
  firstPhotoBase64: string | null;
  secondPhotoBase64: string | null;
}

const initialState: IState = {
  status: null,
  isSubmitted: false,
  firstPhotoBase64: null,
  secondPhotoBase64: null
};

export function driverLicenseReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.LICENSE_STORE: {
      return Object.assign({}, state, payload);
    }
    case ActionTypes.LICENSE_FIRST_PHOTO_CHANGE: {
      return Object.assign({}, state, { photo: payload, firstPhotoBase64: payload });
    }
    case ActionTypes.LICENSE_SECOND_PHOTO_CHANGE: {
      return Object.assign({}, state, { photo: payload, secondPhotoBase64: payload });
    }

    default: {
      return state;
    }
  }
}

export const getFirstPhotoBase64 = createSelector(
  ({ driverLicense }: IRootState): IState => driverLicense,
  ({ firstPhotoBase64 }: IState): string => firstPhotoBase64
);

export const getSecondPhotoBase64 = createSelector(
  ({ driverLicense }: IRootState): IState => driverLicense,
  ({ secondPhotoBase64 }: IState): string => secondPhotoBase64
);
